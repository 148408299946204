import React, {useCallback, useEffect, useState} from 'react';
import './FormCard.css';
import {useTelegram} from "../../hooks/useTelegram";
import {useSearchParams} from "react-router-dom";

const FormCard = () => {
    const {tg} = useTelegram();

    const [summaCard, setSummaCard] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    const [itog, setItog] = useState(searchParams.get("itog") || 0);
    const [inet, setInet] = useState(searchParams.get("inet") || 0);
    const limitBonus = Math.min(Math.round(itog*70/100), inet);

    const onSendData = useCallback(() => {
        const data = {
            summaCard
        }
        tg.sendData(JSON.stringify(data));
    }, [summaCard])


    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])


    useEffect(() => {
        let  t = summaCard
         tg.MainButton.setParams({
                text: 'Использовать: ' + t + ' сум'
            })
        tg.MainButton.show();
   }, [summaCard])


    const onChangeSummaCard = (e) => {
        let s = e.target.value.replace(',', '').replace('.','').replace('-', '')
        if (s.startsWith(0)){
            s = s.substring(1)
        }
            if(s>limitBonus){
            setSummaCard(limitBonus)
        } else {
            setSummaCard(s)
        }

    }

    return (
        <div className={"form"}>

            <h1>Сумма заказа {itog}</h1>
            <p>Сумма накопленных бонусов {inet}</p>
            <p>Можно оплатить бонусами до 70% от счета. Можете использовать {limitBonus}</p>

            <p>Какую сумму бонусов Вы используете</p>
            <input
                className={'input'}
                type="number"
                placeholder={'Введите сумму'}
                value={summaCard}
                onChange={onChangeSummaCard}
                min={1}
                max={limitBonus}

            />

        </div>

    );
};

export default FormCard;
