import React, {useEffect, useMemo, useRef, useState} from 'react';
import './ProductList.css';
/*import {useTelegram} from "../../hooks/useTelegram";
import {useStreetLoad} from "../../hooks/useStreetLoad";
import {useTypeDeliveryLoad} from "../../hooks/useTypeDeliveryLoad";
import Select from "react-select";*/


const ProductList = () => {
/*
   const {tg, queryId} = useTelegram();
  //  const {onLoadStreet, street} = useStreetLoad();
 //   const {onLoadType, type} = useTypeDeliveryLoad();
    const [pickup, setPickup] = useState(true);
    const [street, setStreet] = useState([])

    const type = useRef()

    console.log("component")

  /!*  useMemo( ()=>{
        console.log("type")
        return   onLoadType()
    }, [type])*!/

   /!* useEffect(() =>{
        console.log("type")
        onLoadType()
        if (type.current==="DELIVERYPICKUP"){
            setPickup(false)
        }
    }, [type]);*!/
    useEffect(() =>{
        console.log("sreet")
       // onLoadStreet()
      }, []);


    useMemo( ()=>{
        fetch('http://localhost:8080/webapp/typedelivery?' + new URLSearchParams({
            idUser: 75620024 // user.id
        }), {
            method: 'GET',
        })
            .then(res => res.text())
            .then(
                (result) => {
                    type.current=result;
                    console.log("load typeaaaa " + type.current)
                },

            )

    },[type])

    useEffect( ()=>{
        fetch('http://localhost:8080/webapp/street?' + new URLSearchParams({
            idUser: 75620024 // user.id
        }), {
            method: 'GET',
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setStreet(result);
                    console.log("street")
                }
            )
    },[])*/

    return (

      <div>

      </div>
    );
};
export default ProductList;
