import React, {useCallback, useEffect, useState} from 'react';
import './Feedback.css';
import {useTelegram} from "../../hooks/useTelegram";
import {MDBBtnGroup, MDBRadio} from "mdb-react-ui-kit";
import Select from "react-select";

const Feedback = () => {
    const [textFeedback, setTextFeedback] = useState('');
    const [subject, setSubject] = useState('');
    const [grade, setGrade] = useState('5');
    const [selectGrade, setSelectGrade] = useState(false);

    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(false);


    const  feedbackList = [
        {"value": "1", "label": "Проблема с доставкой"},
        {"value": "2","label": "Проблема с курьером"},
        {"value": "3","label": "Оператор хам"}
    ]
    const {tg} = useTelegram();

    const onSendData = useCallback(() => {
        const data = {
            textFeedback,
            subject,
            grade
        }
        tg.sendData(JSON.stringify(data));
    }, [textFeedback, subject, grade])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Отправить отзыв'
        })
    }, [])

    useEffect(() => {
            tg.MainButton.show();
    }, [])

    const onChangeTextFeedback = (e) => {
        setTextFeedback(e.target.value)
    }

    const onChangeSubject = (e) => {
        if (e != null) {
            console.log(e)
            setSubject(e.label)
        }

    }
    const onChangeGrade = (e) => {
        setGrade(e.target.id)

        if(e.target.id<3){
            setSelectGrade(true)
        } else {
            setSelectGrade(false)
            setSubject('')
        }
    }
    return (
        <div className={"form"}>
            <h3>Сообщение в техподдержку</h3>
         {/*   <h3>{textFeedback} , {subject}, {grade}</h3>*/}
            <input
                className={'input'}
                type="text"
                placeholder={'Текст сообщения'}
                value={textFeedback}
                onChange={onChangeTextFeedback}
            />
            <div className={'fld-group'}>
                <fieldset>
                    <legend>Какую оценку Вы поставите:</legend>
                    <MDBBtnGroup>
                        <MDBRadio
                            btn
                            btnColor='danger'
                            onChange={onChangeGrade}
                            id='1'
                            name='options'
                            wrapperClass='mx-2'
                            wrapperTag='span'
                            label='1'
                           />
                        <MDBRadio
                            onChange={onChangeGrade}
                            btn
                            btnColor='danger'
                            id='2'
                            name='options'
                            wrapperClass='mx-2'
                            wrapperTag='span'
                            label='2'

                        />
                        <MDBRadio
                            onChange={onChangeGrade}
                            btn btnColor='warning' id='3' name='options' wrapperClass='mx-2' wrapperTag='span'
                            label='3'/>
                        <MDBRadio
                            onChange={onChangeGrade}
                            btn btnColor='success' id='4' name='options' wrapperClass='mx-2' wrapperTag='span'
                            label='4'/>
                    <MDBRadio
                        onChange={onChangeGrade}
                        btn btnColor='success' id='5' name='options' wrapperClass='mx-2' wrapperTag='span'
                        label='5'  defaultChecked/>
                    </MDBBtnGroup>

                </fieldset>

            </div>
            <Select
                className={!selectGrade?'basic-single  none':'basic-single'}
                classNamePrefix='select'
                name="street"
                options={feedbackList}
                isClearable={isClearable}
                isSearchable={isSearchable}
                onChange={onChangeSubject}
                placeholder={<div>Выберите в чем проблема</div>}
            />
        </div>
    );
};

export default Feedback;
