import React, {useCallback, useEffect, useRef, useState} from 'react';
import './FormDelivery.css';
import {useTelegram} from "../../hooks/useTelegram";
import Select from "react-select";
import {MDBBtnGroup, MDBRadio} from 'mdb-react-ui-kit';
import {useSearchParams} from "react-router-dom";

const FormDelivery = () => {
    const translation = {
        'RU' : {
            'Укажите номер дома (обязательно)': 'Укажите номер дома (обязательно)',
            'Номер дома (обязательно)': 'Номер дома (обязательно)',
            'Укажите номер квартиры (при наличии)': 'Укажите номер квартиры (при наличии)',
            'Номер квартиры': 'Номер квартиры',
            'Оставьте комментарий к заказу': 'Оставьте комментарий к заказу',
            'Комментарий к заказу': 'Комментарий к заказу',
            'Подтвердить': 'Подтвердить',
        },
        'UZ' : {
            'Укажите номер дома (обязательно)': 'Uy raqamini kiriting (zarur)',
            'Номер дома (обязательно)': 'Uy raqami (zarur)',
            'Укажите номер квартиры (при наличии)': "Kvartira raqamini ko'rsating (agar mavjud bo'lsa)",
            'Номер квартиры': 'Kvartira raqami',
            'Оставьте комментарий к заказу': 'Buyurtma haqida fikringizni qoldiring',
            'Комментарий к заказу': 'Buyurtmaga sharh qoldiring',
            'Подтвердить': 'Tasdiqlang',
        },        
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const [lang, setLang] = useState(getLang());

    function getLang() {
        var lang = searchParams.get("lang") || "RU"
        if(!(lang in translation)) {
            lang = "RU"
        }
        return lang
    }

    const [house, setHouse] = useState(searchParams.get("house") || "");
    const [apart, setApart] = useState(searchParams.get("apart") || "");
    const [comment, setComment] = useState(searchParams.get("comment") || "");
    const houseLimit = 10;
    const apartLimit = 10;
    const commentLimit = 450;

    const [language, setLanguage] = useState("RU")
    const {tg} = useTelegram();

    const onSendData = useCallback(() => {
        const data = { house, apart, comment }
        tg.sendData(JSON.stringify(data));
    }, [house, apart, comment])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
      tg.MainButton.setParams({
         text: translation[lang]['Подтвердить']
        })

    }, [])

    useEffect(() => {
        if (!house) {
            tg.MainButton.hide();
            document.getElementById("house").classList.add("red")
        } else {
            tg.MainButton.show();
            document.getElementById("house").classList.remove("red")
        }

    }, [house, apart])


    const onChangeHouse = (e) => {
        setHouse(e.target.value.substring(0, houseLimit))
    }

    const onChangeApart = (e) => {
        setApart(e.target.value.substring(0, apartLimit))
    }

    const onChangeComment = (e) => {
        setComment(e.target.value.substring(0, commentLimit))
    }

    return (
        <div>
            <div className={"form"}>
                <p id="house">{translation[lang]['Укажите номер дома (обязательно)']} [{house.length}/{houseLimit}]
                <input
                    className={'input'}
                    type="text"
                    placeholder={translation[lang]['Номер дома (обязательно)']}
                    value={house}
                    maxlength={houseLimit}
                    onChange={onChangeHouse}
                /></p>


                <p>{translation[lang]['Укажите номер квартиры (при наличии)']} [{apart.length}/{apartLimit}]
                <input
                    className={'input'}
                    type="text"
                    placeholder={translation[lang]['Номер квартиры']}
                    value={apart}
                    maxlength={apartLimit}
                    onChange={onChangeApart}
                /></p>

                <p>{translation[lang]['Оставьте комментарий к заказу']} [{comment.length}/{commentLimit}]
                <input
                    className={'input'}
                    type="text"
                    placeholder={translation[lang]['Комментарий к заказу']}
                    value={comment}
                    maxlength={commentLimit}
                    onChange={onChangeComment}
                /></p>
            </div>
        </div>
    );
}
export default FormDelivery;
